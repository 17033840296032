import { createRouter, createWebHistory } from 'vue-router'
// import jQuery from 'jquery'

const routes = [
	{ path: '/', name: 'Dashboard', component: () => import('../views/Dashboard.vue') },
	{ path: '/quotes', name: 'Quotes', component: () => import('../views/Quotes.vue') },
	{ path: '/login', name: "Login", component: () => import('../views/Login.vue') },
	{ path: '/logout', name: "Logout", component: () => import('../views/Logout.vue') },
	{ path: '/users', component: () => import('../views/Users.vue') },
	{ path: '/user/:user_id', component: () => import('../views/User.vue') },
	{ path: '/config', component: () => import('../views/Configs.vue') },
	{ path: '/config/:config_id', component: () => import('../views/Config.vue') },
	{ path: '/facility_m1', component: () => import('../views/FacilityData.vue') },
	{ path: '/carriers', component: () => import('../views/Carriers.vue') },
	{ path: '/carrier/:carrier_id', component: () => import('../views/Carrier.vue') },
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

// Hide the mobile menu every time a navigation occurs.
// router.beforeEach((to, from, next) => {
// 	jQuery('#navbar').removeClass('show');
// 	next();
// });

export default router
