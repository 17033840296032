import jQuery from 'jquery'

export default {
	emits: ["startloading", "stoploading", "reload", "sorted"],
	data()
	{
		return {
			api_server: "https://api.mitchinsurance.com",
			search_params: {
				q: "",
				columnFilters: {},
				sort: [],
				page: 1,
				perPage: 25
			},
			vgt_reload_count: 0
		}
	},
	computed: {
		vgtTheme()
		{
			return "";
		}
	},
	methods:
	{
		CORS(request_type, request_url, request_data, success_callback, fail_callback, custom_xhr)
		{
			if(typeof custom_xhr == "undefined" || custom_xhr == null)
			{
				custom_xhr = function() { return new XMLHttpRequest() };
			}

			return jQuery.ajax(
			{
				type: request_type,
				url: this.api_server + request_url,
				cache: false,
				data: request_data,
				xhr: custom_xhr,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true,
				beforeSend: (xhr) => {
					xhr.setRequestHeader("Content-type", "application/json");
					xhr.setRequestHeader("X-CSRF-TOKEN", this.getCookie("csrf_access_token"));
				}
			}).done((data) =>
			{
				if(typeof success_callback == "function")
				{
					success_callback(data);
				}
			}).fail((jqXHR) =>
			{
				if(jqXHR.status == 401 || jqXHR.status == 403)
				{
					if(typeof fail_callback == "function")
					{
						fail_callback(jqXHR);
					}

					if(this.$route.path != "/login")
					{
						this.$router.push("/login").catch(function() { /* ignore error */ });
					}
				}
				else
				{
					if(typeof fail_callback == "function")
					{
						fail_callback(jqXHR);
					}
				}
			});
		},
		CORS2(request_type, request_url, request_data, success_callback, fail_callback, custom_xhr)
		{
			var vm = this;

			if(typeof custom_xhr == "undefined" || custom_xhr == null)
			{
				custom_xhr = function() { return new XMLHttpRequest() };
			}

			return jQuery.ajax(
			{
				type: request_type,
				url: vm.api_server + request_url,
				cache: false,
				data: request_data,
				xhr: custom_xhr,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true,
				beforeSend: function(xhr) {
					// xhr.setRequestHeader("Content-type", "multipart/form-data");
					xhr.setRequestHeader("X-CSRF-TOKEN", vm.getCookie("csrf_access_token"));
				},
				processData: false,  // tell jQuery not to process the data
				contentType: false,  // tell jQuery not to set contentType
			}).done((data) =>
			{
				if(typeof success_callback == "function")
				{
					success_callback(data);
				}
			}).fail((jqXHR) =>
			{
				if(jqXHR.status == 401 || jqXHR.status == 403)
				{
					if(typeof fail_callback == "function")
					{
						fail_callback(jqXHR);
					}

					if(vm.$route.path != "/login")
					{
						vm.$router.push("/login").catch(function() { /* ignore error */ });
					}
				}
				else
				{
					if(typeof fail_callback == "function")
					{
						fail_callback(jqXHR);
					}
				}
			});
		},
		getCookie(cname)
		{
			var name = cname + "=";
			var ca = document.cookie.split(';');
			for(var i=0; i<ca.length; i++)
			{
				var c = ca[i].trim();
				if (c.indexOf(name)==0)
					return c.substring(name.length,c.length);
			}
			return "";
		},
		userHasAction()  // (user, actions)
		{
			return true;
			// if(user == null || typeof user.actions == "undefined" || user.actions == null)
			// {
			// 	return false;
			// }

			// for(var i=0; i<user.actions.length; i++)
			// {
			// 	for(var j=0; j<actions.length; j++)
			// 	{
			// 		if(user.actions[i].constant == actions[j])
			// 		{
			// 			return true;
			// 		}
			// 	}
			// }

			// return false;
		},
		updateParams(newProps)
		{
			this.search_params = Object.assign({}, this.search_params, newProps);
		},
		onPageChange(params)
		{
			this.updateParams({page: params.currentPage});
			this.load();
		},
		onPerPageChange(params)
		{
			this.updateParams({perPage: params.currentPerPage});
			this.load();
		},
		onSortChange(params)
		{
			this.updateParams({sort: params});
			this.load();
		},
		onColumnFilter(params)
		{
			this.updateParams(params);
			this.load();
		},
		nerivon_alert(title, message, type, close_after, callback)
		{
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function(isConfirm)
				{
					return isConfirm;
				};
			}

			if(type == "warning")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-warning"></i>';
			}
			else if(type == "error")
			{
				icon = '<i class="fa-regular fa-exclamation-circle text-danger"></i>';
			}
			else if(type == "success")
			{
				icon = '<i class="fa-regular fa-check-circle text-success"></i>';
			}
			else if(type == "info")
			{
				icon = '<i class="fa-regular fa-info-circle text-info"></i>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal" data-backdrop="static" data-keyboard="false" aria-hidden="true"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', () =>
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', () =>
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		showError(message, text, close_after, callback)
		{
			this.nerivon_alert(message, text, "error", close_after, callback);
		},
		showSuccess(message, text, close_after, callback)
		{
			this.nerivon_alert(message, text, "success", close_after, callback);
		},
		retina: function(src)
		{
			if(window.devicePixelRatio == 2)
			{
				return src.replace(/\.(png|PNG|jpe?g|JPE?G)$/, "@2x.$1");
			}
			else
			{
				return src;
			}
		}
	}
}
