import {createStore} from 'vuex'

const store = createStore({
	state:
	{
		current_time: new Date().getTime() / 1000,
		user: {user_id: null, actions: []},
		loading_count: 0,
		roles: []
	},
	getters:
	{
		user: state =>
		{
			return state.user;
		},
		getUser: (state) => (user_id) =>
		{
			return state.users.find(user => user.user_id === user_id)
		},
		users: state =>
		{
			return state.users;
		},
		current_time: state =>
		{
			return state.current_time;
		},
		logged_in: state =>
		{
			return state.logged_in;
		},
		loading_count: state =>
		{
			return state.loading_count;
		},
		roles: state =>
		{
			return state.roles;
		}
	},
	mutations:
	{
		current_time(state)
		{
			state.current_time = new Date().getTime() / 1000;
		},
		user(state, user)
		{
			state.user = user;
		},
		users(state, users)
		{
			state.users = users;
		},
		logged_in(state, logged_in)
		{
			state.logged_in = logged_in;
		},
		startLoading(state)
		{
			state.loading_count++;
		},
		stopLoading(state)
		{
			state.loading_count--;
		},
		resetLoading(state)
		{
			state.loading_count = 0;
		}
	}
});

export default store;
