<template>
	<div id="app">
		<header class="container-fluid px-0 mb-3">
			<nav class="navbar navbar-light px-3">
				<div>
					<a href="" class="navbar-brand" @click.prevent="toggleMenu()" v-if="logged_in"><i class="fa-regular fa-lg fa-bars"></i></a>
					<a href="" class="navbar-brand" @click.prevent="toggleMenu()"><img alt="Mitch Insurance" src="./assets/logo.svg" id="logo"></a>
					<div class="loading-container">
						<span v-if="loading_count > 0"><div class="spinner-border spinner-border-sm text-primary" role="status"><span class="sr-only">Loading...</span></div></span>
					</div>
				</div>
				<ul class="nav" @click="closeMenu()" v-if="logged_in">
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/config" class="nav-link"><i class="fa-regular fa-fw fa-cogs"></i> Configuration</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/quotes" class="nav-link"><i class="fa-regular fa-fw fa-clipboard-list"></i> Quote Forms</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/facility_m1" class="nav-link"><i class="fa-regular fa-fw fa-motorcycle"></i> Facility M1 Data</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/carriers" class="nav-link"><i class="fa-regular fa-fw fa-buildings"></i> FORTUS Carriers</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/users" class="nav-link"><i class="fa-regular fa-fw fa-users"></i> Users</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'/user/' + user.user_id" class="nav-link"><i class="fa-regular fa-user"></i> My Account</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/logout" class="nav-link"><i class="fa-regular fa-sign-out-alt"></i> Logout</router-link>
					</li>
				</ul>
			</nav>
		</header>

		<div class="mb-3 d-flex container-fluid" @click="closeMenu()">
			<div v-if="logged_in">
				<ul class="nav flex-column">
					<li class="nav-item">
						<router-link to="/" class="nav-link" title="Dashboard"><i class="fa-regular fa-fw fa-tachometer-alt"></i><span v-show="menu_open" class="mx-2"> Dashboard</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/config" class="nav-link" title="Configuration"><i class="fa-regular fa-fw fa-cogs"></i><span v-show="menu_open" class="mx-2"> Configuration</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/quotes" class="nav-link" title="Quote Forms"><i class="fa-regular fa-fw fa-clipboard-list"></i><span v-show="menu_open" class="mx-2"> Quote Forms</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/facility_m1" class="nav-link" title="Facility M1 Data"><i class="fa-regular fa-fw fa-motorcycle"></i><span v-show="menu_open" class="mx-2"> Facility M1 Data</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/carriers" class="nav-link" title="FORTUS Carriers"><i class="fa-regular fa-fw fa-buildings"></i><span v-show="menu_open" class="mx-2"> FORTUS Carriers</span></router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/users" class="nav-link" title="Users"><i class="fa-regular fa-fw fa-users"></i><span v-show="menu_open" class="mx-2"> Users</span></router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'/user/' + user.user_id" title="My Account" class="nav-link"><i class="fa-regular fa-fw fa-user"></i><span v-show="menu_open" class="mx-2"> My Account</span></router-link>
					</li>
					<li class="nav-item" v-if="logged_in">
						<router-link to="/logout" class="nav-link" title="Logout"><i class="fa-regular fa-fw fa-sign-out-alt"></i><span v-show="menu_open" class="mx-2"> Logout</span></router-link>
					</li>
				</ul>
			</div>

			<div :class="className" class="pt-3" @click="closeMenu()">
				<router-view @login="login" @logout="logout" />
			</div>
		</div>

		<footer class="container-fluid p-0 mb-3" @click="closeMenu()" v-if="logged_in">
			<hr>
			<nav>
				<ul class="nav d-flex justify-content-center">
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/config" class="nav-link"><i class="fa-regular fa-fw fa-cogs"></i> Configuration</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/quotes" class="nav-link"><i class="fa-regular fa-fw fa-clipboard-list"></i> Quote Forms</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/facility_m1" class="nav-link"><i class="fa-regular fa-fw fa-motorcycle"></i> Facility M1 Data</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/carriers" class="nav-link"><i class="fa-regular fa-fw fa-buildings"></i> FORTUS Carriers</router-link>
					</li>
					<li class="nav-item" v-if="userHasAction(user, ['IDDQD', 'ADMIN'])">
						<router-link to="/users" class="nav-link"><i class="fa-regular fa-fw fa-users"></i> Users</router-link>
					</li>
					<li class="nav-item">
						<router-link :to="'/user/' + user.user_id" class="nav-link"><i class="fa-regular fa-user"></i> My Account</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/logout" class="nav-link"><i class="fa-regular fa-sign-out-alt"></i> Logout</router-link>
					</li>
				</ul>
			</nav>
		</footer>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'App',
		data() {
			return {
				first_load: true,
				menu_open: false,
				public_urls: [
					/^\/login$/
				]
			}
		},
		computed: {
			className()
			{
				if(["Login", "Logout"].indexOf(this.$route.name) != -1)
				{
					return "container";
				}
				else
				{
					return "container-fluid";
				}
			},
			...mapGetters([
				"user",
				"logged_in",
				"loading_count"
			])
		},
		methods: {
			checkSession()
			{
				this.CORS("GET", "/authenticate", "", (response) =>
				{
					this.$store.commit("user", response.user);
					this.$store.commit("logged_in", true);

					if(this.first_load)
					{
						// Load all globally used supporting data.
						// this.loadNotifications();
						// this.loadLeadStatuses();
						// this.loadLeadSources();
						// this.loadInsuranceTypes();
						// this.loadInsurers();
						// this.loadGroups();
						// this.loadNumbers();
						// this.loadRoles();
						// this.loadActions();
						// this.loadGroups();
						// this.loadAccounts();
						// this.loadUsers();
						// this.loadCannedResponses();
					}

					this.first_load = false;
				});
			},
			login()
			{
				this.$store.commit("logged_in", true);
			},
			logout()
			{
				this.$store.commit("user", {user_id: null, actions: []});
				this.$store.commit("logged_in", false);
			},
			toggleMenu()
			{
				if(!this.logged_in)
				{
					return;
				}

				this.menu_open = (this.menu_open == 1 ? 0 : 1);
			},
			closeMenu()
			{
				this.menu_open = 0;
			},
			checkPath: function(path)
			{
				// Don't check security on public URLs.
				for(var i=0; i<this.public_urls.length; i++)
				{
					if(path.match(this.public_urls[i]) !== null)
					{
						return;
					}
				}

				this.checkSession();

				if(this.interval != null)
				{
					clearInterval(this.interval);
				}

				this.interval = setInterval(() =>
				{
					this.checkSession();
				}, 60000);
			}
		},
		mounted: function()
		{
			this.checkPath(window.location.pathname);
		},
		watch:
		{
			$route (to, from)
			{
				if(to == from)
				{
					return;
				}

				this.checkPath(this.$route.path);
			}
		}
	}
</script>
